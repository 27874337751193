@use 'styles/style' as *;

:local {

  .wrap {
    display: flex;
    justify-content: center;

    .wrapContent {
      width: 800px;
      padding: 0 $contentGutter;
      box-sizing: border-box;

      @include at-below-breakpoint(lg) {
        width: 700px;
        padding: 0 $contentGutter;
      }
      @include at-below-breakpoint(md) {
        width: 500px;
        padding: 0 calc($contentGutter / 2);
      }
      @include at-below-breakpoint(sm) {
        width: 100%;
        padding: 0 20px;
      }

    }

  }

}