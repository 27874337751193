@use 'styles/style' as *;

:local {

  .wrapImg {
    @extend %img-wrap;
  }

  .wrapImg + p {
    margin-top: $subSectionPadding;
  }

}