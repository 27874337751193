@use 'styles/style' as *;

:local {

  .wrapMasonryGrid {
    display: flex;
    width: auto;
    @extend %img-margins;

    .masonryGridCol {
      padding-left: 20px; /* gutter size */
      background-clip: padding-box;

      .wrapThumb {
        position: relative;

        &:hover {
          .wrapVeil {
            opacity: 1;
          }
        }

        img {
          border-radius: $imgBorderRadiusLg;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          object-fit: cover;
          margin-bottom: 20px; /* gutter size */
        }

        .wrapVeil {
          background-color: rgba(0, 0, 0, 0.25);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: $imgBorderRadiusLg;
          opacity: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          transition:
            opacity 0.25s ease;

          svg {
            font-size: 2em;
            color: white;
          }

        }

      }

      @include at-below-breakpoint(sm) {
        padding: 0;

        .wrapThumb {
          img, .wrapVeil {
            border-radius: $imgBorderRadiusSm;
          }
        }

      }

    }

  }

}