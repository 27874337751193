// Padding
$contentGutter: 100px;
$sectionPadding: 100px;
$subSectionPadding: 30px;
$refMargin: -50px;

// UI
$imgBorderRadiusLg: 4px;
$imgBorderRadiusSm: 3px;

// Responsive elements
$breakpointXl: 1200px;
$breakpointLg: 1000px;
$breakpointMd: 800px;
$breakpointSm: 600px;