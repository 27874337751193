@use 'styles/themes' as *;

body {
  background: var(--themeBgColor);
}

body {
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  color: var(--themeTextColor);
}

h1,
h2,
h3,
h4,
p,
hr {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 50px;
  line-height: 58px;
  color: var(--themeTaglineColor);
}

h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 0.025em;
  color: var(--themeSectionHeaderColor);
}

p, ol {
  font-size: 14px;
  line-height: 24px;
  color: var(--themeTextColorSecondary);
}

a {
  color: var(--themeLinkColor);
}

ol {
  margin: 0;
  padding-left: 16px;
}

li {
  margin-bottom: 10px;
}

nav {
  color: var(--themeNavTextColor);
}

span,
em {
  color: var(--themeSpanColor);
}