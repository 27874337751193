@use 'styles/vars' as *;

// UI
@mixin remove-appearance() {
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  border: none;
  appearance: none;
}

// Responsive breakpoints
@mixin at-breakpoint($size) {
  @if $size == xl {
    @media (min-width: $breakpointXl) { @content; }
  }
  @else if $size == lg {
    @media (min-width: $breakpointLg) and (max-width: ($breakpointXl - 1)) { @content; }
  }
  @else if $size == md {
    @media (min-width: $breakpointMd) and (max-width: ($breakpointLg - 1)) { @content; }
  }
  @else if $size == sm {
    @media (min-width: $breakpointSm) and (max-width: ($breakpointMd - 1)) { @content; }
  }
  @else if $size == xs {
    @media (max-width: ($breakpointSm - 1)) { @content; }
  }
}

@mixin at-below-breakpoint($size) {
  @if $size == xl {
    @media (max-width: ($breakpointXl - 1)) { @content; }
  }
  @else if $size == lg {
    @media (max-width: ($breakpointLg - 1)) { @content; }
  }
  @else if $size == md {
    @media (max-width: ($breakpointMd - 1)) { @content; }
  }
  @else if $size == sm {
    @media (max-width: ($breakpointSm - 1)) { @content; }
  }
  @else if $size == xs {
    @media (max-width: ($breakpointSm - 1)) { @content; }
  }
}

@mixin at-above-breakpoint($size) {
  @if $size == xl {
    @media (min-width: $breakpointXl) { @content; }
  }
  @else if $size == lg {
    @media (min-width: $breakpointLg) { @content; }
  }
  @else if $size == md {
    @media (min-width: $breakpointMd) { @content; }
  }
  @else if $size == sm {
    @media (min-width: $breakpointSm) { @content; }
  }
  @else if $size == xs {
    @media (min-width: $breakpointSm) { @content; }
  }
}