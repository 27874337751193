@use 'styles/mixins' as *;
@use 'styles/vars' as *;

%header {
  display: flex;
  justify-content: space-between;
  padding: $sectionPadding 0 20px 0;

  @include at-below-breakpoint(sm) {
    padding: calc($sectionPadding / 2) 0 20px 0;
  }

  .name {
    font-size: 15px;
    font-weight: 500;
    color: var(--themeMastColor);

    a {
      text-decoration: none;
      color: inherit;
    }

  }

  nav {
    display: flex;
    flex-direction: column;
    gap: 5px;

    a, button {
      @include remove-appearance();
      font-family: inherit;
      font-size: 14px;
      text-align: right;
      cursor: pointer;
      color: inherit;
      text-decoration: none;

      transition:
        opacity 0.25s ease;

      &:hover {
        opacity: 0.75;

        svg {
          margin-right: 5px;
        }

      }

      svg {
        transition:
          margin-right 0.25s ease;
      }

    }

  }

}

%mast {
  margin-bottom: $sectionPadding;

  h1 {
    @include at-below-breakpoint(lg) {
      font-size: 50px;
      line-height: 58px;
    }
    @include at-below-breakpoint(md) {
      font-size: 40px;
      line-height: 48px;
    }
    @include at-below-breakpoint(sm) {
      font-size: 36px;
      line-height: 42px;
    }

  }

}

%img-wrap {
  border-radius: $imgBorderRadiusLg;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @extend %img-margins;

  @include at-below-breakpoint(sm) {
    border-radius: $imgBorderRadiusSm;
  }

}

%img-margins {
  margin: $subSectionPadding calc($contentGutter * -1) 0 calc($contentGutter * -1);

  @include at-below-breakpoint(md) {
    margin: $subSectionPadding calc($contentGutter * -1 / 2) 0 calc($contentGutter * -1 / 2);
  }
  @include at-below-breakpoint(sm) {
    margin: $subSectionPadding 0 0 0;
  }

}

%main {
  section {
    margin-bottom: $sectionPadding;

    button.toTopBtn {
      @include remove-appearance();
    }

    p + p {
      margin-top: 20px;
    }

    a {
      text-decoration: underline;
      font-weight: 500;

      &:hover {
        text-decoration: none;
      }

    }

  }

}