
@import 'styles/vars';

:local {

  .wrap {
    position: relative;
    top: $refMargin;
  }

}