:root {
  --themeBgColor: white;
  --themeTextColor: black;
  --themeTextColorSecondary: #222;
  --themeNavTextColor: inherit;
  --themeMastColor: inherit;
  --themeLinkColor: inherit;
  --themeSectionHeaderColor: inherit;
  --themeSpanColor: inherit;
  --themeTaglineColor: inherit;
}

[data-theme="dark"] {
  --themeBgColor: #282c34;
  --themeTextColor: #f2f2f2;
  --themeTextColorSecondary: #abb2bf;
  --themeNavTextColor: #d19a66;
  --themeMastColor: #61afee;
  --themeLinkColor: #e06b75;
  --themeSectionHeaderColor: #c678dd;
  --themeSpanColor: #98c379;
  --themeTaglineColor: #545b68;
}