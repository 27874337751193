
@import 'styles/style';
@import 'styles/vars';
@import 'styles/mixins';

:local {

  .wrap {

    header {
      @extend %header;
      padding: $sectionPadding 0 40px 0;

      @include at-below-breakpoint(sm) {
        padding: calc($sectionPadding / 2) 0 40px 0;
      }

    }

    .wrapMast {
      @extend %mast;
    }

    main {
      @extend %main;
    }

  }

}