
@import 'styles/style';
@import 'styles/mixins';

:local {

  .wrap {
    position: relative;
    height: 13px;
    margin-bottom: 10px;

    button {
      @include remove-appearance();
      cursor: pointer;
      position: absolute;
      right: 0;

      transition:
        opacity 0.25s ease;

      &:hover {
        opacity: 0.75;
      }

    }

  }

}

:export {
  navColor: var(--themeNavTextColor);
}